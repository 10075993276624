import {
  AuthenticationResult,
  IPublicClientApplication,
  SilentRequest,
  createStandardPublicClientApplication,
} from "@azure/msal-browser";
import posthog from "posthog-js";
import { MSAL_CONFIG } from "./config";
import { APPLICATION_INSIGHTS } from "./appinsights";

class Msal {
  private pca: IPublicClientApplication | undefined = undefined;
  private _account: AuthenticationResult | undefined = undefined;

  set account(account: AuthenticationResult | undefined) {
    if (this._account !== account && account !== undefined) {
      this._account = account;
      this.pca?.setActiveAccount(account.account);
      APPLICATION_INSIGHTS.setAuthenticatedUserContext(account.account.username);
      posthog.identify(account.account.username);
    } else if (account === undefined) {
      this._account = account;
      APPLICATION_INSIGHTS.clearAuthenticatedUserContext();
      posthog.reset();
    }
  }

  /* this function may throw and should be called from a try/catch block */
  public async ssoGetToken(scopes: string[]): Promise<string> {
    const userAccount = await this.ssoGetUserIdentity(scopes);
    this.account = userAccount;
    return userAccount.accessToken;
  }

  /* this function may throw and should be called from a try/catch block */
  async ssoGetUserIdentity(scopes: string[]): Promise<AuthenticationResult> {
    if (this.pca === undefined) {
      this.pca = await createStandardPublicClientApplication(MSAL_CONFIG);
    }
    const request: SilentRequest = { scopes: scopes, account: this.pca.getAllAccounts()[0] };
    try {
      try {
        return await this.pca.acquireTokenSilent(request);
      } catch (error) {
        /* fallback to interactive token acquisition */
        return await this.pca.acquireTokenPopup(request);
      }
    } catch (error) {
      throw new Error(`Unable to acquire access token: ${error}`);
    }
  }
}

export const MSAL = new Msal();
