/*global CustomFunctions */
import { ParameterSchema } from "@ddb/parameter-service";
import { toMap } from "../../shared/asyncIterator";
import { Batcher } from "../../shared/batch";
import { parameterService, unpaginate } from "../../shared/ddb";
import { isGuid, formulaErrorHandling } from "../../shared/helpers";
import { FormulaErrorFormat } from "../../shared/types";

class AssetParametersBatcher extends Batcher<ParameterSchema> {
  static id = (assetId: string, parameterTypeId: string) => `${assetId}:${parameterTypeId}`;

  async _getResults(keys: string[]): Promise<Map<string, ParameterSchema>> {
    const client = parameterService();
    const [assetIds, parameterTypeIds] = keys
      .map((key) => key.split(":") as [string, string])
      .reduce(
        ([assetIds, parameterTypeIds], [assetId, parameterTypeId]) => [
          assetIds.add(assetId),
          parameterTypeIds.add(parameterTypeId),
        ],
        [new Set<string>(), new Set<string>()]
      );
    const [parameters, project_level_params] = await Promise.all([
      toMap(
        unpaginate(
          (after) =>
            client.getAllParameters({
              after,
              assetId: Array.from(assetIds),
              parameterTypeId: Array.from(parameterTypeIds),
            }),
          (data) => data.data.parameters,
          (data) => data.data.paging?.cursors?.after
        ),
        (parameter) => AssetParametersBatcher.id(parameter.parents.at(0)?.id || "", parameter.parameter_type.id),
        (parameter) => parameter
      ),
      toMap(
        unpaginate(
          (after) =>
            client.getAllParameters({
              after,
              projectId: Array.from(assetIds),
              parameterTypeId: Array.from(parameterTypeIds),
              projectParameter: true,
            }),
          (data) => data.data.parameters,
          (data) => data.data.paging?.cursors?.after
        ),
        (parameter) => AssetParametersBatcher.id(assetIds.values().next().value, parameter.parameter_type.id),
        (parameter) => parameter
      ),
    ]);
    return new Map([...project_level_params, ...parameters]);
  }
}

const asssetParametersBatcher = new AssetParametersBatcher(10, "assetParameters");

/**
 * Gets the ID of a parameter on an asset.
 * @customfunction PARAMETER.ASSET_PARAMETER_ID
 * @param assetId The asset ID.
 * @param parameterTypeId The parameter type ID.
 * @returns The parameter ID.
 * @streaming
 */
export function assetParameterId(
  assetId: string,
  parameterTypeId: string,
  invocation: CustomFunctions.StreamingInvocation<string>
): void {
  if (!isGuid(assetId)) {
    invocation.setResult(formulaErrorHandling(FormulaErrorFormat.ASSET_ID));
    return;
  }
  if (!isGuid(parameterTypeId)) {
    invocation.setResult(formulaErrorHandling(FormulaErrorFormat.PARAMETER_TYPE_ID));
    return;
  }
  asssetParametersBatcher.enqueue(
    AssetParametersBatcher.id(assetId, parameterTypeId),
    invocation,
    (parameter) => parameter.id
  );
}

/**
 * Gets the value of a parameter on an asset.
 * @customfunction PARAMETER.ASSET_PARAMETER_VALUE
 * @param assetId The asset ID.
 * @param parameterTypeId The parameter type ID.
 * @returns The parameter value.
 * @streaming
 */
export function assetParameterValue(
  assetId: string,
  parameterTypeId: string,
  invocation: CustomFunctions.StreamingInvocation<string>
): void {
  if (!isGuid(assetId)) {
    invocation.setResult(formulaErrorHandling(FormulaErrorFormat.ASSET_ID));
    return;
  }
  if (!isGuid(parameterTypeId)) {
    invocation.setResult(formulaErrorHandling(FormulaErrorFormat.PARAMETER_TYPE_ID));
    return;
  }
  asssetParametersBatcher.enqueue(
    AssetParametersBatcher.id(assetId, parameterTypeId),
    invocation,
    (parameter) => parameter.selected_entry?.values?.at(0)?.value ?? ""
  );
}

/**
 * Gets the unit symbol of a parameter on an asset.
 * @customfunction PARAMETER.ASSET_PARAMETER_UNIT_SYMBOL
 * @param assetId The asset ID.
 * @param parameterTypeId The parameter type ID.
 * @returns The parameter unit symbol.
 * @streaming
 */
export function assetParameterUnitSymbol(
  assetId: string,
  parameterTypeId: string,
  invocation: CustomFunctions.StreamingInvocation<string>
): void {
  if (!isGuid(assetId)) {
    invocation.setResult(formulaErrorHandling(FormulaErrorFormat.ASSET_ID));
    return;
  }
  if (!isGuid(parameterTypeId)) {
    invocation.setResult(formulaErrorHandling(FormulaErrorFormat.PARAMETER_TYPE_ID));
    return;
  }
  asssetParametersBatcher.enqueue(
    AssetParametersBatcher.id(assetId, parameterTypeId),
    invocation,
    (parameter) => parameter.selected_entry?.values?.at(0)?.unit?.symbol ?? ""
  );
}

CustomFunctions.associate("PARAMETER.ASSET_PARAMETER_ID", assetParameterId);
CustomFunctions.associate("PARAMETER.ASSET_PARAMETER_VALUE", assetParameterValue);
CustomFunctions.associate("PARAMETER.ASSET_PARAMETER_UNIT_SYMBOL", assetParameterUnitSymbol);