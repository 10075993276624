/*global CustomFunctions */
import { ProjectSchema } from "@ddb/environment-context-service";
import { toMap } from "../../shared/asyncIterator";
import { Batcher } from "../../shared/batch";
import { environmentContextService, unpaginate } from "../../shared/ddb";

class ProjectIdBatcher extends Batcher<ProjectSchema> {
  async _getResults(keys: string[]): Promise<Map<string, ProjectSchema>> {
    const env = environmentContextService();
    return await toMap(
      unpaginate(
        (after) => env.getProjects({ number: keys, after }),
        (data) => data.data.projects,
        (data) => data.data.paging?.cursors?.after
      ),
      (project) => project.number
    );
  }
}

const projectIdBatcher = new ProjectIdBatcher(10, "projectId");

/**
 * Gets DDB project ID.
 * @customfunction PROJECT.ID
 * @param projectNumber Project number without a dash.
 * @returns The DDB project ID.
 * @streaming
 */
export function projectId(projectNumber: string, invocation: CustomFunctions.StreamingInvocation<string>): void {
  if (!/^\d{8}$/.test(projectNumber)) {
    throw new CustomFunctions.Error(
      CustomFunctions.ErrorCode.invalidValue,
      "Invalid project number. Project number must be 8 digits."
    );
  }

  projectIdBatcher.enqueue(projectNumber, invocation, (project) => project.project_id);
}

CustomFunctions.associate("PROJECT.ID", projectId);