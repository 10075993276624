import { TemplateSchema } from "@ddb/template-service";
import { DataSetSchema } from "@ddb/parameter-service";
import { ProjectSchema } from "@ddb/environment-context-service";

export enum DdbEnvironment {
  Develop = "develop",
  Sandbox = "sandbox",
  Production = "production",
}

export enum QueryFormat {
  Template = "template",
  Manual = "manual",
}

export const QUERY_FORMAT_DESCRIPTIONS = {
  [QueryFormat.Template]: "Select parameters from an existing template.",
  [QueryFormat.Manual]: "Manually filter all parameters.",
};

// TODO: define Filters type
type Filters = unknown;

interface BaseQuery {
  format: QueryFormat;
  environment: DdbEnvironment;
  project_id?: ProjectSchema["project_id"];
  name?: string;
  filters?: Filters;
}

export interface SavedQuery extends BaseQuery {
  id: string;
  project_id: ProjectSchema["project_id"];
  name: string;
}

interface ManualQuery extends BaseQuery {
  format: QueryFormat.Manual;
}

interface TemplateQuery extends BaseQuery {
  format: QueryFormat.Template;
  template_id?: TemplateSchema["id"];
  data_sets_ids?: Array<DataSetSchema["id"]>;
}

export function isTemplateQuery(query: Query): query is TemplateQuery {
  return query.format === QueryFormat.Template;
}

export function isManualQuery(query: Query): query is ManualQuery {
  return query.format === QueryFormat.Manual;
}

export function isSavedQuery(query: SavedQuery | Query): query is SavedQuery {
  return query["id"] !== undefined;
}

export type Query = ManualQuery | TemplateQuery;

export type SavedQueries = readonly SavedQuery[];
