import {
  Configuration,
  ConfigurationParameters,
  Environment as DDBEnvironment,
  EnvironmentContextApi,
} from "@ddb/environment-context-service";
import { ParameterApi } from "@ddb/parameter-service";
import { MSAL } from "./msal";
import { TemplateApi } from "@ddb/template-service";
import { TaxonomyApi } from "@ddb/taxonomy-service";
import { DDB_ENVRIONMENT } from "./config";

export function getConfiguration(version: string): Configuration {
  const params: ConfigurationParameters = {
    basePath: DDBEnvironment[DDB_ENVRIONMENT],
    accessToken: (_: string, scopes: string[]) => MSAL.ssoGetToken(scopes),
    baseOptions: {
      headers: {
        Version: version,
        "App-Client-Type": "Excel Add-in",
      },
    },
  };
  const config = new Configuration(params);
  return config;
}

export function templateService(): TemplateApi {
  const config = getConfiguration("0");
  return new TemplateApi(config);
}

export function environmentContextService(): EnvironmentContextApi {
  const config = getConfiguration("2");
  return new EnvironmentContextApi(config);
}

export function parameterService(): ParameterApi {
  const config = getConfiguration("1");
  return new ParameterApi(config);
}

export function taxonomyService(): TaxonomyApi {
  const config = getConfiguration("0");
  return new TaxonomyApi(config);
}

export async function* unpaginate<T, O>(
  requestFunction: (after: string | undefined) => Promise<T>,
  getInner: (data: T) => Iterable<O>,
  getAfter: (data: T) => string | undefined
) {
  let after: string | undefined = undefined;
  do {
    const data = await requestFunction(after);
    const inner = getInner(data);
    yield* inner;
    after = getAfter(data);
  } while (after);
}
