import { Configuration } from "@azure/msal-browser";
import { Resource } from "@opentelemetry/resources";
import { diag, DiagLogLevel } from "@opentelemetry/api";
import { DdbEnvironment } from "../shared/types";
import {
  ATTR_SERVICE_NAME,
  ATTR_SERVICE_VERSION,
  SEMRESATTRS_DEPLOYMENT_ENVIRONMENT,
} from "@opentelemetry/semantic-conventions";

import PackageJSON from "../../package.json";
import { Snippet } from "@microsoft/applicationinsights-web";
import { PostHogConfig } from "posthog-js";

const { name, version } = PackageJSON;

/* ddb-excel envrionment */
export enum Environment {
  Local = "local",
  Development = "develop",
  UT = "ut",
  Production = "production",
}

/* envrionment variables */
export const ENVIRONMENT = (process.env.ENVIRONMENT as Environment) || Environment.Local;
export const APPINSIGHTS_CONNECTION_STRING = (process.env.APPINSIGHTS_CONNECTION_STRING as string) || "";
export const POSTHOG_API_KEY = (process.env.POSTHOG_API_KEY as string) || "";
export const POSTHOG_HOST = (process.env.POSTHOG_HOST as string) || "";
export const MSAL_CLIENT_ID = (process.env.MSAL_CLIENT_ID as string) || "";
export const MSAL_AUTHORITY = (process.env.MSAL_AUTHORITY as string) || "";
export const OTEL_DIAG_LOGGER_LEVEL =
  (parseInt(process.env.OTEL_DIAG_LOGGER_LEVEL as string) as DiagLogLevel) || DiagLogLevel.ERROR;

diag.debug(`Environment: ${ENVIRONMENT}`);
diag.debug(`AppInsights Connection String: ${APPINSIGHTS_CONNECTION_STRING}`);
diag.debug(`PostHog API Key: ${POSTHOG_API_KEY}`);
diag.debug(`PostHog Host: ${POSTHOG_HOST}`);
diag.debug(`MSAL Client ID: ${MSAL_CLIENT_ID}`);
diag.debug(`MSAL Authority: ${MSAL_AUTHORITY}`);
diag.debug(`OTEL Diag Logger Level: ${OTEL_DIAG_LOGGER_LEVEL}`);

/* ddb environment */
function ddbEnvironment(): DdbEnvironment {
  switch (ENVIRONMENT) {
    case Environment.Local:
      return DdbEnvironment.Develop;
    case Environment.Development:
      return DdbEnvironment.Develop;
    case Environment.UT:
      return DdbEnvironment.Sandbox;
    case Environment.Production:
      return DdbEnvironment.Production;
  }
}

export const DDB_ENVRIONMENT: DdbEnvironment = ddbEnvironment();

diag.debug(`DDB Environment: ${DDB_ENVRIONMENT}`);

/* ddb-excel opentelemetry resource */
export const OTEL_RESROUCE = new Resource({
  [ATTR_SERVICE_NAME]: name,
  [ATTR_SERVICE_VERSION]: version,
  [SEMRESATTRS_DEPLOYMENT_ENVIRONMENT]: ENVIRONMENT,
  "DDB.Environment": DDB_ENVRIONMENT,
});

diag.debug(`OTEL Resource: ${OTEL_RESROUCE}`);

/* application insights configuration */
export const APPLICATION_INSIGHTS_CONFIG: Partial<Snippet["config"]> = {
  connectionString: APPINSIGHTS_CONNECTION_STRING,
  disableCookiesUsage: true,
  enableAjaxErrorStatusText: true,
  enableAjaxPerfTracking: true,
  enableCorsCorrelation: true,
  enableRequestHeaderTracking: true,
  enableResponseHeaderTracking: true,
  enablePerfMgr: true,
  enableAutoRouteTracking: true,
  enableUnhandledPromiseRejectionTracking: true,
  enableDebug: ENVIRONMENT === Environment.Local || ENVIRONMENT === Environment.Development,
  loggingLevelConsole: ENVIRONMENT === Environment.Local || ENVIRONMENT === Environment.Development ? 2 : 0,
  loggingLevelTelemetry: ENVIRONMENT === Environment.Local || ENVIRONMENT === Environment.Development ? 2 : 1,
};

/* posthog configuration */
export const POSTHOG_CONFIG: Partial<PostHogConfig> = { api_host: POSTHOG_HOST };

/* msal configuration */
export const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: MSAL_CLIENT_ID,
    authority: MSAL_AUTHORITY,
    redirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  telemetry: {
    application: {
      appName: name,
      appVersion: version,
    },
  },
};
