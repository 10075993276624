/*global CustomFunctions */
import { ParameterSchema, RevisionSchema } from "@ddb/parameter-service";
import { at, first, toMap } from "../../shared/asyncIterator";
import { Batcher } from "../../shared/batch";
import { parameterService, unpaginate } from "../../shared/ddb";
import { isGuid, formulaErrorHandling } from "../../shared/helpers";
import { FormulaErrorFormat } from "../../shared/types";

class ParametersBatcher extends Batcher<ParameterSchema> {
  async _getResults(keys: string[]): Promise<Map<string, ParameterSchema>> {
    const client = parameterService();
    const [parameters, project_level_params] = await Promise.all([
      toMap(
        unpaginate(
          (after) => client.getAllParameters({ after, parameterId: keys, projectParameter: false }),
          (data) => data.data.parameters,
          (data) => data.data.paging?.cursors?.after
        ),
        (parameter) => parameter.id,
        (parameter) => parameter
      ),
      toMap(
        unpaginate(
          (after) => client.getAllParameters({ after, parameterId: keys, projectParameter: true }),
          (data) => data.data.parameters,
          (data) => data.data.paging?.cursors?.after
        ),
        (parameter) => parameter.id,
        (parameter) => parameter
      ),
    ]);
    return new Map([...parameters, ...project_level_params]);
  }
}

const parametersBatcher = new ParametersBatcher(10, "parameters");

/**
 * Gets DDB parameter value.
 * @customfunction PARAMETER.VALUE
 * @param parameterId The parameter ID.
 * @param [revision] The parameter revision. If omitted, version = "latest".
 * @returns The parameter value.
 * @streaming
 */
export function parameterValue(
  parameterId: string,
  revision: string | number | null,
  invocation: CustomFunctions.StreamingInvocation<any>
): void {
  if (!isGuid(parameterId)) {
    invocation.setResult(formulaErrorHandling(FormulaErrorFormat.PARAMETER_ID));
    return;
  }
  if (
    (revision !== null && typeof revision !== "number" && revision !== "latest" && !isGuid(revision)) ||
    revision === 0
  ) {
    invocation.setResult(formulaErrorHandling(FormulaErrorFormat.INVALID_REVISION));
    return;
  }

  /* return latest revision */
  if (revision === null || revision === "latest") {
    parametersBatcher.enqueue(
      parameterId,
      invocation,
      (parameter) => parameter.selected_entry?.values?.at(0)?.value ?? ""
    );
  } else {
    parametersBatcher.enqueue(parameterId, invocation, async (parameter) => {
      const entryRevision = await getParameterRevision(parameter, revision);
      return entryRevision?.values.at(0)?.value || "";
    });
  }
}

async function getParameterRevision(parameter: ParameterSchema, revision: string | number): Promise<RevisionSchema> {
  const client = parameterService();
  const entryId = (parameter.selected_entry?.id || "") as Object;
  const revisions = unpaginate(
    (after) => client.getEntryRevisions({ after, entryId, order: "asc" }),
    (data) => data.data.revisions,
    (data) => data.data.paging?.cursors?.after
  );
  const entryRevision =
    typeof revision === "number"
      ? await at(revisions, revision - 1)
      : await first(revisions, (rev) => rev.id === revision);

  /* throw error if revision not found */
  if (!entryRevision) {
    throw formulaErrorHandling(FormulaErrorFormat.INVALID_REVISION);
  }
  return entryRevision;
}

/**
 * Gets DDB parameter name.
 * @customfunction PARAMETER.PARAMETER_TYPE_NAME
 * @param parameterId The parameter ID.
 * @returns The parameter name.
 * @streaming
 */
export function parameterparameterTypeName(
  parameterId: string,
  invocation: CustomFunctions.StreamingInvocation<string>
): void {
  if (!isGuid(parameterId)) {
    invocation.setResult(formulaErrorHandling(FormulaErrorFormat.PARAMETER_ID));
    return;
  }
  parametersBatcher.enqueue(parameterId, invocation, (parameter) => parameter.parameter_type.name ?? "");
}

/**
 * Gets DDB parameter source ID.
 * @customfunction PARAMETER.SOURCE_ID
 * @param parameterId The parameter ID.
 * @returns The parameter source ID.
 * @streaming
 */
export function parameterSourceId(parameterId: string, invocation: CustomFunctions.StreamingInvocation<string>): void {
  if (!isGuid(parameterId)) {
    invocation.setResult(formulaErrorHandling(FormulaErrorFormat.PARAMETER_ID));
    return;
  }
  parametersBatcher.enqueue(parameterId, invocation, (parameter) => parameter.selected_entry?.source?.id ?? "");
}

/**
 * Gets DDB parameter status.
 * @customfunction PARAMETER.STATUS
 * @param parameterId The parameter ID.
 * @returns The parameter status.
 * @streaming
 */
export function parameterStatus(parameterId: string, invocation: CustomFunctions.StreamingInvocation<string>): void {
  if (!isGuid(parameterId)) {
    invocation.setResult(formulaErrorHandling(FormulaErrorFormat.PARAMETER_ID));
    return;
  }
  parametersBatcher.enqueue(parameterId, invocation, (parameter) => parameter.selected_entry?.status ?? "unanswered");
}

/**
 * Gets DDB parameter unit symbol.
 * @customfunction PARAMETER.UNIT_SYMBOL
 * @param parameterId The parameter ID.
 * @returns The parameter unit symbol.
 * @streaming
 */
export function parameterUnitSymbol(
  parameterId: string,
  invocation: CustomFunctions.StreamingInvocation<string>
): void {
  if (!isGuid(parameterId)) {
    invocation.setResult(formulaErrorHandling(FormulaErrorFormat.PARAMETER_ID));
    return;
  }
  parametersBatcher.enqueue(
    parameterId,
    invocation,
    (parameter) => parameter.selected_entry?.values?.at(0)?.unit?.symbol ?? ""
  );
}

CustomFunctions.associate("PARAMETER.VALUE", parameterValue);
CustomFunctions.associate("PARAMETER.PARAMETER_TYPE_NAME", parameterparameterTypeName);
CustomFunctions.associate("PARAMETER.SOURCE_ID", parameterSourceId);
CustomFunctions.associate("PARAMETER.STATUS", parameterStatus);
CustomFunctions.associate("PARAMETER.UNIT_SYMBOL", parameterUnitSymbol);