/*global CustomFunctions */
import { AssetHierarchyRef } from "@ddb/parameter-service";
import { Batcher } from "../../shared/batch";
import { parameterService } from "../../shared/ddb";
import { isGuid } from "../../shared/helpers";

class HierarchyBatcher extends Batcher<AssetHierarchyRef[]> {
  async _getResults(keys: string[]): Promise<Map<string, AssetHierarchyRef[]>> {
    const client = parameterService();
    const result = await client.getAssetHierarchy({ assetIds: keys });
    return new Map(keys.map((key, index) => [key, result.data.hierarchies[index]]));
  }
}

const hierarchyBatcher = new HierarchyBatcher(1, "paths");

/**
 * Gets DDB asset path.
 * @customfunction ASSET.PATH
 * @param assetId The asset ID.
 * @param [separator] The separator to use. If omitted = "/"
 * @returns The asset path.
 * @streaming
 */
export function assetPath(
  assetId: string,
  separator: string | null,
  invocation: CustomFunctions.StreamingInvocation<string>
): void {
  if (!separator) {
    separator = "/";
  }
  if (!isGuid(assetId)) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Asset ID must be a GUID.");
  }
  hierarchyBatcher.enqueue(assetId, invocation, (hierarchy) => hierarchy.map((asset) => asset.name).join(separator));
}

CustomFunctions.associate("ASSET.PATH", assetPath);