import { diag, context } from "@opentelemetry/api";
import { SeverityNumber } from "@opentelemetry/api-logs";
import { ExportResult, ExportResultCode } from "@opentelemetry/core";
import { ReadableLogRecord, LogRecordExporter } from "@opentelemetry/sdk-logs";
import { PushMetricExporter, ResourceMetrics, DataPointType } from "@opentelemetry/sdk-metrics";
import { SpanExporter, ReadableSpan } from "@opentelemetry/sdk-trace-base";
import { runTargetUnload } from "@microsoft/applicationinsights-core-js";
import {
  ApplicationInsights,
  ICustomProperties,
  IMetricTelemetry,
  SeverityLevel,
  ITraceTelemetry,
  Snippet,
} from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { APPLICATION_INSIGHTS_CONFIG } from "./config";

/* application insights and react plugin */
export const REACT_PLUGIN = new ReactPlugin();
const CONFIG: Snippet["config"] = { ...APPLICATION_INSIGHTS_CONFIG, extensions: [REACT_PLUGIN] };
export const APPLICATION_INSIGHTS = new ApplicationInsights({ config: CONFIG });

const MS_AI_MESSAGE = "Microsoft.ApplicationInsights.Message" as const;
const MS_AI_METRIC = "Microsoft.ApplicationInsights.Metric" as const;
const MS_AI_TRACE = "Microsoft.ApplicationInsights.Trace" as const;
type TelmetryData = ReadableSpan[] | ReadableLogRecord[] | ResourceMetrics;
type ConvertableData = ReadableSpan | ReadableLogRecord | ResourceMetrics;
type ConvertedData = ITraceTelemetry | IMetricTelemetry | IMetricTelemetry[];

abstract class BaseApplicationInsightsExporter {
  protected enabled = false;
  abstract export(data: TelmetryData, resultCallback: (result: ExportResult) => void): Promise<void>;
  protected abstract convert(data: ConvertableData): [ConvertedData, ICustomProperties];

  constructor(protected readonly insights = APPLICATION_INSIGHTS) {
    try {
      if (this.insights.core.isInitialized) {
        this.enabled = true;
        return;
      }
      this.insights.loadAppInsights();
      this.enabled = true;
    } catch (error) {
      diag.error(`Failed to initialize ${this.constructor.name}: ${error}`);
    }
  }

  public async shutdown() {
    this.enabled = false;
    await this.insights.flush();
    await runTargetUnload(this.insights);
    return Promise.resolve();
  }
}

export class ApplicationInsightsTraceExporter extends BaseApplicationInsightsExporter implements SpanExporter {
  async export(spans: ReadableSpan[], resultCallback: (result: ExportResult) => void) {
    await context.with(context.active(), async () => {
      try {
        for (const [item, properties] of spans.map((span) => this.convert(span))) {
          this.insights.trackTrace(item, properties);
        }
        resultCallback({ code: ExportResultCode.SUCCESS });
      } catch (error) {
        resultCallback({ code: ExportResultCode.FAILED });
      }
    });
  }

  protected convert(span: ReadableSpan): [ITraceTelemetry, ICustomProperties] {
    const item = {
      message: MS_AI_TRACE,
      properties: { ...span.attributes },
    };
    const properties: ICustomProperties = {};
    return [item, properties];
  }
}

export class ApplicationInsightsMetricsExporter extends BaseApplicationInsightsExporter implements PushMetricExporter {
  public async export(metrics: ResourceMetrics, resultCallback: (result: ExportResult) => void) {
    await context.with(context.active(), async () => {
      try {
        const [items, properties] = this.convert(metrics);
        for (const item of items) {
          this.insights.trackMetric(item, properties);
        }
        resultCallback({ code: ExportResultCode.SUCCESS });
      } catch (error) {
        resultCallback({ code: ExportResultCode.FAILED });
      }
    });
  }

  public async forceFlush() {
    try {
      await this.insights.flush(true);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  protected convert(metrics: ResourceMetrics): [IMetricTelemetry[], ICustomProperties] {
    const items: IMetricTelemetry[] = [];
    for (const scope of metrics.scopeMetrics) {
      for (const metric of scope.metrics) {
        if (metric.dataPointType === DataPointType.SUM) {
          const name = `${MS_AI_METRIC}.${scope.scope.name}.${metric.descriptor.name}`;
          const data = metric.dataPoints[0];
          const item: IMetricTelemetry = { name, average: data.value as number };
          items.push(item);
        }
      }
    }
    const properties: ICustomProperties = {};
    return [items, properties];
  }
}

export class ApplicationInsightsLogExporter extends BaseApplicationInsightsExporter implements LogRecordExporter {
  public async export(records: ReadableLogRecord[], resultCallback: (result: ExportResult) => void) {
    await context.with(context.active(), async () => {
      try {
        for (const [item, properties] of records.map((record) => this.convert(record))) {
          this.insights.trackTrace(item, properties);
        }
        resultCallback({ code: ExportResultCode.SUCCESS });
      } catch (error) {
        resultCallback({ code: ExportResultCode.FAILED });
      }
    });
  }

  protected convert(record: ReadableLogRecord): [ITraceTelemetry, ICustomProperties] {
    const item: ITraceTelemetry = {
      message: MS_AI_MESSAGE,
      severityLevel: this.servertyNumberToSeverityLevel(record.severityNumber || SeverityNumber.INFO),
      properties: { ...record.attributes },
    };
    const properties: ICustomProperties = {};
    return [item, properties];
  }

  protected servertyNumberToSeverityLevel(severityNumber: SeverityNumber): SeverityLevel {
    if (severityNumber) {
      if (severityNumber > 0 && severityNumber < 9) {
        return SeverityLevel.Verbose;
      } else if (severityNumber >= 9 && severityNumber < 13) {
        return SeverityLevel.Information;
      } else if (severityNumber >= 13 && severityNumber < 17) {
        return SeverityLevel.Warning;
      } else if (severityNumber >= 17 && severityNumber < 21) {
        return SeverityLevel.Error;
      } else if (severityNumber >= 21 && severityNumber < 25) {
        return SeverityLevel.Critical;
      }
    }
    return SeverityLevel.Information;
  }
}
